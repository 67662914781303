var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "reg-faq",
    },
    [
      _c(
        "div",
        {
          staticClass: "wuwow-card",
        },
        [
          _c(
            "div",
            {
              staticClass: "wuwow-card-head",
            },
            [
              _c(
                "h4",
                {
                  staticClass: "wuwow-card-title",
                },
                [_vm._v(_vm._s(_vm.$t("layout.common_problem")))]
              ),
            ]
          ),
          _vm._l(_vm.faqTypeList, function (faqType) {
            return _vm.filterCategory(_vm.faqList, faqType.id).length
              ? _c(
                  "div",
                  {
                    key: faqType.id,
                    staticClass: "wuwow-card-body",
                  },
                  [
                    _c(
                      "h5",
                      {
                        staticClass: "h5-faq-category",
                      },
                      [_vm._v(" " + _vm._s(faqType.displayName))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "wuwow-accordion",
                      },
                      _vm._l(
                        _vm.filterCategory(_vm.faqList, faqType.id),
                        function (qusetion, index) {
                          return _c(
                            "b-card",
                            {
                              key: index,
                              attrs: {
                                "no-body": "",
                              },
                            },
                            [
                              _c(
                                "b-card-header",
                                {
                                  directives: [
                                    {
                                      name: "b-toggle",
                                      rawName: "v-b-toggle",
                                      value: faqType.displayName + "-" + index,
                                      expression:
                                        "faqType.displayName + '-' + index",
                                    },
                                  ],
                                  attrs: {
                                    "header-tag": "header",
                                    role: "tab",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setCurrentShow(
                                        faqType.displayName,
                                        index
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v("Ｑ " + _vm._s(qusetion.title)),
                                  _c("font-awesome-icon", {
                                    class: [
                                      _vm.currentShowType ==
                                        faqType.displayName &&
                                      index === _vm.currentShowIndex
                                        ? "d-none"
                                        : "d-block",
                                    ],
                                    attrs: {
                                      icon: "angle-down",
                                    },
                                  }),
                                  _c("font-awesome-icon", {
                                    class: [
                                      _vm.currentShowType ==
                                        faqType.displayName &&
                                      index === _vm.currentShowIndex
                                        ? "d-block"
                                        : "d-none",
                                    ],
                                    attrs: {
                                      icon: "angle-up",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    id: faqType.displayName + "-" + index,
                                    accordion: "analysia-accordion",
                                    role: "tabpanel",
                                  },
                                },
                                [
                                  _c("b-card-body", [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(qusetion.description),
                                      },
                                    }),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      1
                    ),
                  ]
                )
              : _vm._e()
          }),
          _c(
            "div",
            {
              staticClass: "txt-faq-ps",
            },
            [
              _vm._v(
                "若還有任何疑問，歡迎至WUWOW Jr線上客服的Line留言，或是撥打技術客服專線喔！:)"
              ),
              _c("br"),
              _c("br"),
              _vm._v("WUWOW Jr線上客服：Line ID ="),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.companyLineUrl,
                  },
                },
                [_vm._v(_vm._s(_vm.companyLineId))]
              ),
              _c("br"),
              _vm._v("技術客服專線："),
              _c(
                "a",
                {
                  attrs: {
                    href: "tel:" + _vm.companyPhone,
                  },
                },
                [_vm._v(_vm._s(_vm.companyPhone))]
              ),
              _c("br"),
              _c("br"),
            ]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }